<template>
  <CCard>
    <CCardHeader>
      <a @click="goBack">
        <CIcon class="me-2 text-dark" :icon="cilArrowLeft" size="xl" />
      </a>
      <strong class="ms-2">Componenten Beheren</strong>
    </CCardHeader>
    <CRow>
      <CCol :xs="12" :lg="6">
        <CCard class="ms-3 mt-3 mb-3">
          <CCardHeader>
            <strong>Beschikbare componenten</strong>
          </CCardHeader>
          <CCardBody>
            <dynamic-grid
              :name="availableComponentsIdentifier"
              :metadata="componentsMetadata"
              :data="availableComponents"
              :autoPagination="true"
            />
          </CCardBody>
        </CCard>
        <CButton
          :disabled="availableComponentsSelectedRow?.id === undefined"
          @click="addComponentToTool"
          class="btn btn-primary btn-sm mb-3 float-end"
        >
          Toevoegen aan tool
        </CButton>
      </CCol>
      <CCol :xs="12" :lg="6">
        <CCard class="me-3 mb-3 mt-3">
          <CCardHeader>
            <strong>Componenten in tool</strong>
          </CCardHeader>
          <CCardBody>
            <dynamic-grid
              :name="componentsInToolIdentifier"
              :metadata="componentMetadata"
              :data="currentComponentsInTool"
              :autoPagination="true"
            />
          </CCardBody>
        </CCard>
        <CButton
          :disabled="componentsInToolSelectedRow?.id === undefined"
          @click="removeComponentFromTool"
          class="btn btn-primary btn-sm mb-3"
        >
          Verwijderen uit tool
        </CButton>
      </CCol>
    </CRow>
  </CCard>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, toRefs, watch } from "vue";
import DynamicGrid from "../dynamicgrid/DynamicGrid.vue";
import CrudService from "../../services/CrudService";
import { CIcon } from "@coreui/icons-vue";
import { cilArrowLeft } from "@coreui/icons";
import { useRouter } from "vue-router";

export default {
  name: "ComponentManager",
  components: { DynamicGrid, CIcon },
  props: {
    toolId: {
      required: true,
    },
  },
  setup(props) {
    const { toolId: toolId } = toRefs(props);
    const store = useStore();
    const router = useRouter();

    const availableComponentsIdentifier = ref("availableComponents");
    const componentsInToolIdentifier = ref("componentsInTool");
    store.commit("dynamicgrid/SET_NEW_GRID", componentsInToolIdentifier.value);
    store.commit("dynamicgrid/SET_NEW_GRID", availableComponentsIdentifier.value);

    const componentsMetadata = computed(() => store.state.toolscrud.componentsMetadata);
    const availableComponents = computed(() => store.state.toolscrud.componentsData);
    const componentMetadata = computed(() => store.state.toolscrud.componentMetadata);
    const currentComponentsInTool = computed(
      () => store.state.toolscrud.currentComponentsInTool
    );

    const availableComponentsSelectedRow = computed(() => {
      return store.state.dynamicgrid[availableComponentsIdentifier.value]
        ?.selectedRows[0];
    });

    const componentsInToolSelectedRow = computed(() => {
      return store.state.dynamicgrid[componentsInToolIdentifier.value]?.selectedRows[0];
    });
    const componentsInTool = computed(
      () => store.state.dynamicgrid[componentsInToolIdentifier.value]?.rows
    );
    const oldComponentsInTool = computed(() => store.state.toolscrud.oldComponentsInTool);
    watch(
      [toolId],
      () => {
        if (toolId?.value) {
          store.dispatch("toolscrud/getComponentsData");
          store.dispatch("toolscrud/getComponentsByToolId", toolId.value);

          store.dispatch("toolscrud/getComponentsMetadata");
        }
      },
      { immediate: true }
    );

    watch(
      componentsInTool,
      () => {
        if (componentsInTool.value) {
          if (
            componentsInTool.value.length == oldComponentsInTool.value.length &&
            componentsInTool.value.length > 0
          ) {
            const getCorrespondingOldComponent = (component) =>
              oldComponentsInTool.value.filter((comp) => comp.id == component.id)[0];
            if (
              componentsInTool.value.some(
                (comp) => getCorrespondingOldComponent(comp).aantal != comp.aantal
              )
            ) {
              CrudService.postData(
                `components/toolcomponents/${toolId.value}`,
                componentsInTool.value
                  .filter((row) => row.aantal !== undefined && parseInt(row.aantal) > 0)
                  .map((row) => {
                    return { componentId: row.id, aantal: parseInt(row.aantal) };
                  })
              )
                .then((response) => {
                  store.commit(
                    "HANDLE_RESPONSE",
                    {
                      response: response.data,
                      successMessage: "De wijzigingen zijn opgeslagen.",
                    },
                    { root: true }
                  );
                })
                .catch((err) => store.commit("ADD_TOAST_ERROR", err, { root: true }));
            }
          }
        }
        store.commit("toolscrud/SET_OLD_COMPONENTS_IN_TOOL", {
          payload: JSON.parse(JSON.stringify(componentsInTool.value)),
        });
      },
      { deep: true }
    );
    function addComponentToTool() {
      const row = availableComponentsSelectedRow.value;
      row.aantal = 1;
      store.state.dynamicgrid[componentsInToolIdentifier.value].rows.push(row);
      store.state.toolscrud.currentComponentsInTool.push(row);
      const indexToRemove = store.state.dynamicgrid[
        availableComponentsIdentifier.value
      ].rows.findIndex((value) => value === availableComponentsSelectedRow.value);
      store.state.dynamicgrid[availableComponentsIdentifier.value].rows.splice(
        indexToRemove,
        1
      );
      store.state.dynamicgrid[availableComponentsIdentifier.value].selectedRows = [];

      CrudService.postData(
        `components/toolcomponents/${toolId.value}`,
        componentsInTool.value
          .filter((row) => row.aantal !== undefined && parseInt(row.aantal) > 0)
          .map((row) => {
            return { componentId: row.id, aantal: parseInt(row.aantal) };
          })
      )
        .then((response) => {
          store.commit(
            "HANDLE_RESPONSE",
            {
              response: response.data,
              successMessage: "De wijzigingen zijn opgeslagen.",
            },
            { root: true }
          );
        })
        .catch((err) => store.commit("ADD_TOAST_ERROR", err, { root: true }));
    }

    function removeComponentFromTool() {
      CrudService.deleteByQueries("components", [
        {
          queryName: "toolId",
          queryValue: toolId.value,
        },
        {
          queryName: "componentId",
          queryValue: componentsInToolSelectedRow.value.id,
        },
      ])
        .then((response) => {
          if (response.data.success) {
            store.state.dynamicgrid[availableComponentsIdentifier.value].rows.push(
              componentsInToolSelectedRow.value
            );
            const indexToRemove = store.state.dynamicgrid[
              componentsInToolIdentifier.value
            ].rows.findIndex((value) => value === componentsInToolSelectedRow.value);
            store.state.dynamicgrid[componentsInToolIdentifier.value].rows.splice(
              indexToRemove,
              1
            );
            store.state.toolscrud.currentComponentsInTool.splice(indexToRemove, 1);

            store.state.toolscrud.currentComponentsInTool =
              store.state.dynamicgrid[componentsInToolIdentifier.value].rows;
            store.state.dynamicgrid[componentsInToolIdentifier.value].selectedRows = [];
          }

          store.commit(
            "HANDLE_RESPONSE",
            {
              response: response.data,
              successMessage: "Het geselecteerde component is succesvol verwijderd.",
            },
            { root: true }
          );
        })
        .catch((err) => store.commit("ADD_TOAST_ERROR", err, { root: true }));
    }

    function goBack() {
      router.push({
        name: "toolscrud",
        params: {
          id: toolId.value,
        },
      });
    }

    return {
      componentsMetadata,
      availableComponents,
      componentMetadata,
      currentComponentsInTool,
      availableComponentsSelectedRow,
      componentsInToolSelectedRow,
      addComponentToTool,
      removeComponentFromTool,
      goBack,
      componentsInToolIdentifier,
      availableComponentsIdentifier,
      cilArrowLeft
    };
  },
};
</script>
